<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { roundNumber } from '@bc-library/common'
import { TransitionPresets, useTransition } from '@vueuse/core'

const props = withDefaults(defineProps<{
  height?: string
  color?: string
  bg?: string
  value?: number
  max?: number
  loading?: boolean
  lowerTextColor?: string
  upperTextColor?: string
}>(), {
  height: 'h-5',
  color: 'bg-success-500 dark:bg-success-600',
  bg: 'bg-success-400/20',
  max: 1,
  lowerTextColor: 'text-gray-700 dark:text-gray-200',
  upperTextColor: 'text-white dark:text-gray-100',
})
const percent = computed(() => {
  return (props.value || 0) / (props.max || 1) * 100
})
const addaptBackground = computed(() => {
  return percent.value < 50
})

const source = ref(0)

const output = useTransition(source, {
  duration: 700,
  transition: TransitionPresets.easeInOutCubic,
  onFinished() {
    if (!props.loading)
      return
    source.value = source.value > 0 ? 0 : 100
  },
})
if (props.loading)
  source.value = 100
watch(() => props.loading, (value) => {
  if (value)
    source.value = 100
})
</script>

<template>
  <div class="w-full relative rounded-xl flex items-center overflow-hidden shadow-inner" :class="[bg, height]">
    <p v-if="!loading" class="text-xs absolute top-0 left-1/2 -translate-x-1/2" :class="[addaptBackground ? lowerTextColor : upperTextColor]">
      {{ roundNumber(percent) }}%
    </p>
    <div class="h-full" :class="[color, { 'transition-all': !loading }]" :style="[!loading ? `width:${percent}%;` : `margin-left: ${output}%; width:15%;`]" />
  </div>
</template>

<style scoped>
.text-percent{
  text-shadow: 0 0 1px #000;
}
</style>
