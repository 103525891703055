import { default as axios } from 'axios'
import companyContact from '@/services/companyContact/companyContactHttp.service'
import user from '@/services/user/userHttp.service'
import resource from '@/services/resource/resourceHttp.service'
import excel from '@/services/excel/excelHttp.service'
import job from '@/services/job/jobHttp.service'
import team from '@/services/team/teamHttp.service'
import office from '@/services/office/officeHttp.service'
import training from '@/services/training/trainingHttp.service'
import trainingLocation from '@/services/trainingLocation/trainingLocationHttp.service'
import trainingSession from '@/services/trainingSession/trainingSessionHttp.service'
import trainingCategory from '@/services/trainingCategory/trainingCategoryHttp.service'
import trainingTrainer from '@/services/trainingTrainer/trainingTrainerHttp.service'
import trainer from '@/services/trainer/traininerHttp.service'
import jobOffer from '@/services/jobOffer/jobOfferHttp.service'
import firm from '@/services/firm/firmHttp.service'
import document from '@/services/document/documentHttp.service'
import project from '@/services/project/projectHttp.service'
import skill from '@/services/skill/skillHttp.service'
import projectCategory from '@/services/projectCategory/projectCategoryHttp.service'
import search from '@/services/search/searchHttp.service'
import recruitment from '@/services/recruitment/recruitmentHttp.service'
import room from '@/services/room/roomHttp.service'
import company from '@/services/company/companyHttp.service'
import comment from '@/services/comment/commentHttp.service'
import formular from '@/services/formular/formularHttp.service'
import projectStatus from '@/services/projectStatus/projectStatusHttp.service'
import leave from '@/services/leave/leaveHttp.service'
import sector from '@/services/sector/sectorHttp.service'
import holiday from '@/services/holiday/holidayHttp.service'
import dashboard from '@/services/dashboard/dashboardHttp.service'
import usergroup from '@/services/usergroup/usergroupHttp.service'
import timesheet from '@/services/timesheet/timesheetHttp.service'
import contractTypeHttpService from '@/services/contractType/contractTypeHttp.service'
import timesheetActivity from '@/services/timesheetActivity/timesheetActivityHttp.service'
import invoicingInformation from '@/services/invoicingInformation/invoicingInformationHttp.service'
import publicHoliday from '@/services/publicHoliday/publicHolidayHttp.service'
import leaveType from '@/services/leaveType/leaveTypeHttp.service'
import evaluation from '@/services/evaluation/evaluationHttp.service'
import recruitmentMailTemplate from '@/services/recruitmentMailTemplate/recruitmentMailTemplateHttp.service'
import recruitmentRejectionCategory from '@/services/recruitmentRejectionCategory/recruitmentRejectionCategory.service'
import profileHttpService from '@/services/profile/profileHttp.service'
import invoice from '@/services/invoice/invoiceHttp.service'
import axiosConfig from '@/config/axios.config'
import management from '@/services/management/managementHttp.service'
import invoicingProjects from '@/services/invoicingProjects/invoicingProjectsHttp.service'
import invoicingTerm from '@/services/invoicingTerm/invoicingTermHttp.service'
import bankAccount from '@/services/bankAccount/bankAccountHttp.service'
import expense from '@/services/expense/expenseHttp.service'
import school from '@/services/school/schoolHttp.service'
import message from '@/services/message/messageHttp.service'
import RecruitmentWebsite from '@/services/recruitmentWebsite/recruitmentWebsiteHttp.service'
import boardingProcess from '@/services/onboardingProcess/boardingProcessHttp.service'
import jobOfferStatistic from '@/services/jobOfferStatistic/jobOfferStatisticHttp.service'
import userLogAction from '@/services/userLogAction/userLogActionHttp.service'

const updatedAxios = axiosConfig(axios)

export const userApi = user(updatedAxios)
export const excelApi = excel(updatedAxios)
export const resourceApi = resource(updatedAxios)
export const jobApi = job(updatedAxios)
export const jobOfferApi = jobOffer(updatedAxios)
export const companyApi = company(updatedAxios)
export const firmApi = firm(updatedAxios)
export const recruitmentMailTemplateApi = recruitmentMailTemplate(updatedAxios)
export const teamApi = team(updatedAxios)
export const projectApi = project(updatedAxios)
export const officeApi = office(updatedAxios)
export const schoolApi = school(updatedAxios)
export const sectorApi = sector(updatedAxios)
export const documentApi = document(updatedAxios)
export const skillApi = skill(updatedAxios)
export const dashboardApi = dashboard(updatedAxios)
export const projectCategoryApi = projectCategory(updatedAxios)
export const trainingApi = training(updatedAxios)
export const trainerApi = trainer(updatedAxios)
export const messageApi = message(updatedAxios)
export const usergroupApi = usergroup(updatedAxios)
export const searchApi = search(updatedAxios)
export const timesheetApi = timesheet(updatedAxios)
export const contractTypeApi = contractTypeHttpService(updatedAxios)
export const timesheetActivityApi = timesheetActivity(updatedAxios)
export const leaveApi = leave(updatedAxios)
export const holidayApi = holiday(updatedAxios)
export const projectStatusApi = projectStatus(updatedAxios)
export const publicHolidayApi = publicHoliday(updatedAxios)
export const leaveTypeApi = leaveType(updatedAxios)
export const recruitmentApi = recruitment(updatedAxios)
export const roomApi = room(updatedAxios)
export const invoicingInformationApi = invoicingInformation(updatedAxios)
export const commentApi = comment(updatedAxios)
export const companyContactApi = companyContact(updatedAxios)
export const evaluationApi = evaluation(updatedAxios)
export const trainingTrainerApi = trainingTrainer(updatedAxios)
export const trainingLocationApi = trainingLocation(updatedAxios)
export const trainingSessionApi = trainingSession(updatedAxios)
export const trainingCategoryApi = trainingCategory(updatedAxios)
export const recruitmentRejectionCategoryApi = recruitmentRejectionCategory(updatedAxios)
export const formularApi = formular(updatedAxios)
export const profileApi = profileHttpService(updatedAxios)
export const managementApi = management(updatedAxios)
export const invoiceApi = invoice(updatedAxios)
export const invoicingProjectsApi = invoicingProjects(updatedAxios)
export const invoicingTermApi = invoicingTerm(updatedAxios)
export const bankAccountApi = bankAccount(updatedAxios)
export const recruitmentWebsiteApi = RecruitmentWebsite(updatedAxios)
export const boardingProcessApi = boardingProcess(updatedAxios)
export const expenseApi = expense(updatedAxios)
export const jobOfferStatisticApi = jobOfferStatistic(updatedAxios)
export const userLogActionApi = userLogAction(updatedAxios)
