import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IUser } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { resourceApi } from '@/services/api'
import { useUserStore } from '@/config/store/userStore'
import router from '@/config/router'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'users'
let userStore = null

const login = (axios: AxiosInstance) => async ({ pageOptions, ...data }: any) => {
  pageOptions.login.isLoading = true
  const response = await axios.post(`${BASE_URL}/auth/local`, data).catch((err) => {
    console.log('err', err)
    pageOptions.login.isError = true
  })
  if (response?.data) {
    console.log('response.data.', response.data)
    if (!userStore)
      userStore = useUserStore()
    userStore.token = response.data.token
    const connectedUser = await resourceApi.getConnectedResource().promise
    console.log('connectedUser', connectedUser)
    userStore.connection({ token: response.data.token, user: connectedUser })
    pageOptions.login.isLoading = false
    await router.push({ name: 'Home' })
  }
  else {
    pageOptions.login.isLoading = false
  }
}

const updateUser = (axios: IFactoryFunctionRequest<IUser, Partial<IUser>>) => (userId: string, userToSend: Partial<IUser>) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${userId}`,
    data: userToSend,
  })
}

const updateEmail = (axios: IFactoryFunctionRequest<IUser, Partial<IUser>>) => (userId: string, userToSend: { email: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${userId}/updateMail`,
    data: userToSend,
  })
}

const searchUserPicture = (axios: IFactoryFunctionRequest<IUser, { name: string }>) => (search: { name: string }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/getGoogleImages`,
    data: search,
  })
}

export default (axios: AxiosInstance) => ({
  login: login(axios),
  updateUser: updateUser(useAxios(axios)),
  searchUserPicture: searchUserPicture(useAxios(axios)),
  updateEmail: updateEmail(useAxios(axios)),
})
