import type { RouteRecordRaw } from 'vue-router'
import { guardCreation360, guardCreationEvaluation, guardCreationPeriod, guardCreationProject, guardEditEvaluation, guardEvaluationAccess } from '@/config/router/navigationsGuards/guardEvaluation'

const MODULE_NAME = 'EVALUATION'
const MODULE_ID = 7

const evaluationRoutes: Array<RouteRecordRaw> = [
  {
    path: '/evaluation/external/:evaluationId',
    meta: {
      requiresAuth: false,
    },
    name: 'EvaluationExternal',
    component: () => import('@/views/evaluation/EvaluationExternal.vue'),
  },
  {
    path: '/evaluation',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardEvaluationAccess],
    children: [
      {
        path: '',
        name: 'Evaluations',
        component: () => import('@/views/evaluation/List.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.evaluations',
        },
        beforeEnter: [],
      },
      {
        path: ':evaluationId',
        name: 'Evaluation',
        component: () => import('@/views/evaluation/Evaluation.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [],
      },
      {
        path: ':evaluationId/edit',
        name: 'EvaluationEdit',
        component: () => import('@/views/evaluation/EvaluationEdit.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [guardEditEvaluation],
      },
      {
        path: 'create',
        name: 'EvaluationCreation',
        component: () => import('@/views/evaluation/creation/EvaluationCreation.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: [guardCreationEvaluation],
        redirect(to) {
          return { name: 'EvaluationCreationSelection' }
        },
        children: [
          {
            path: '',
            name: 'EvaluationCreationSelection',
            component: () => import('@/views/evaluation/creation/Selection.vue'),
            meta: {
              requiresAuth: true,
              titleTranslationKey: 'pageTitle.evaluationCreationSelection',
            },
            beforeEnter: [],
          },
          {
            path: '360',
            name: 'EvaluationCreation360',
            component: () => import('@/views/evaluation/creation/360.vue'),
            meta: {
              requiresAuth: true,
              titleTranslationKey: 'pageTitle.evaluationCreation360',
            },
            beforeEnter: [guardCreation360],
          },
          {
            path: 'period',
            name: 'EvaluationCreationPeriod',
            component: () => import('@/views/evaluation/creation/Period.vue'),
            meta: {
              requiresAuth: true,
              titleTranslationKey: 'pageTitle.evaluationCreationPeriod',
            },
            beforeEnter: [guardCreationPeriod],
          },
          {
            path: 'project',
            name: 'EvaluationCreationProject',
            component: () => import('@/views/evaluation/creation/Project.vue'),
            meta: {
              requiresAuth: true,
              titleTranslationKey: 'pageTitle.evaluationCreationProject',
            },
            beforeEnter: [guardCreationProject],
          },
        ],
      },

    ],

  },

]

export default evaluationRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
