import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPaginated } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import type { IJobOffer } from '@/services/jobOffer/IJobOffers'

const resource = 'jobOffers'

const getAllPublic = (axios: IFactoryFunctionRequest) => ({ firmId }: { firmId: string }): IFactoryResponse<IJobOffer[]> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/public/${firmId}`,
  })
}

const getAllPublicPaginated = (axios: IFactoryFunctionRequest) => ({ firmId, query }: { firmId: string; query: object }): IFactoryResponse<IJobOffer[]> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/public/paginated/${firmId}`,
    query,
  })
}
const getPaginated = (axios: IFactoryFunctionRequest) => ({ query }: { query: object }): IFactoryResponse<IJobOffer[]> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/paginated`,
    query,
  })
}

const onePublic = (axios: IFactoryFunctionRequest<IJobOffer, undefined>) => ({ jobId, firmId }: { jobId: string; firmId: string }): IFactoryResponse<IPaginated<IJobOffer>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/onePublic/${firmId}/${jobId}`,
  })
}

export default (axios: AxiosInstance) => ({
  getAllPublic: getAllPublic(useAxios(axios)),
  getAllPublicPaginated: getAllPublicPaginated(useAxios(axios)),
  onePublic: onePublic(useAxios(axios)),
  getPaginated: getPaginated(useAxios(axios)),
})
