import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'recruitmentMailTemplates'

const getAll = (axios: IFactoryFunctionRequest) => () => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm`,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
})
