import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IJob } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'jobs'

const getAll = (axios: IFactoryFunctionRequest<Array<IJob>, undefined>) => ({ firmId }: { firmId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

const getAllFilter = (axios: IFactoryFunctionRequest<Array<Pick<IJob, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query: options,
  })
}
const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<IJob, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: options,
  })
}
const getOneGantt = (axios: IFactoryFunctionRequest<IJob, undefined>) => (jobId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/getGanttToAdd/${jobId}`,
  })
}

const getRecruitmentNewJob = (axios: IFactoryFunctionRequest<IJob, undefined>) => (jobId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/recruitment/newPosition/${jobId}`,
  })
}

export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getAllFilter: getAllFilter(useAxios(axios)),
  getFilter: getFilter(useAxios(axios)),
  getOneGantt: getOneGantt(useAxios(axios)),
  getRecruitmentNewJob: getRecruitmentNewJob(useAxios(axios)),
})
