export interface IItemMenu {
  display: string
  isHeader?: boolean
  prependIcon?: boolean
  appendBadge?: boolean
  borderColor?: string
}
export interface IGenericFilter {
  _id: string
  display: string
}

export interface IGenericSelect {
  _id: string
  display: string
  value: boolean
}
export type TColors =
	| 'primary'
	| 'success'
	| 'warning'
	| 'base'
	| 'base-varient'
	| 'error'
	| 'errorLight'
	| 'loading'
	| 'disabled'
	| 'disabledLight'
	| 'transparent'

export type TSize = 'mini' | 'dense' | 'small' | 'normal'

export enum EModalPosition {
  base = 'base',
  bottom = 'bottom',
  right = 'right',
  left = 'left',
}
export enum EModalAnimation {
  base = 'fade-scale',
  bottom = 'fade-y-bottom',
  right = 'fade-x-right',
}

export interface IFile {
  fileName: string
  base64: string
  fileType: string
  fileSize: number
  fileDescription?: string
}
