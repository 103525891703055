import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 5

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.project.isAccess
}
export const guardProjectCreation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.createAccess ? user.profile.modules.project.createAccess : { name: 'Home' }
}
export const guardPlanningResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.planningResourceAccess ? user.profile.modules.project.planningResourceAccess : { name: 'Home' }
}
export const guardStats = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.statsAccess ? user.profile.modules.project.statsAccess : { name: 'Home' }
}
export const guardSettings = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.project.settingsAccess ? user.profile.modules.project.settingsAccess : { name: 'Home' }
}

export const guardEnterProject = () => {
  const userStore = useUserStore()
  const user = userStore.user

  return user.profile.modules.project.overviewOnlyPublic === false
}

export const guardProjectAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}
